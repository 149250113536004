<template>
  <div>
    <el-dialog
      :before-close="cancelBtn"
      :close-on-click-modal="false"
      :title="work_name"
      :visible.sync="dialogVisible"
      @open="open"
      top="0"
      width="650px"
    >
      <div class="referHome">
        <el-form
          :model="formData"
          :rules="referRules"
          class="readForm"
          label-position="top"
          ref="formRef_stu"
        >
          <!-- 老师布置的作业 -->
          <div class="cont_title">
            <div class="line"></div>
            <div class="lf">
              <span>作业要求</span>
            </div>
          </div>
          <div class="cont_title_2">作业内容</div>
          <div class="cont_info" v-html="work_contexts">}</div>
          <div class="cont_title_2">相关附件</div>
          <div class="upload_box">
            <div v-if="teaFileList.length > 0">
              <div
                :key="index"
                class="img_list"
                v-for="(item, index) in teaFileList"
              >
                <div>
                  <el-image :src="fileImg"></el-image>
                  <div class="item_name">{{ item.name }}</div>
                  <div @click="downloadStuFile(item.url)" class="item_remove">
                    下载
                  </div>
                </div>
              </div>
            </div>

            <div class="img_list" v-if="teaFileList.length <= 0">
              <div>
                <div class="item_name">暂无相关附件</div>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
          <!-- 我的作业（未提交） -->
          <div class="cont_title">
            <div class="line"></div>
            我的作业
          </div>
          <el-form-item
            label="文字描述(如超过300字，建议以附件形式上传)"
            prop="stu_answer"
          >
            <el-input
              :disabled="is_end===false"
              :maxlength="300"
              placeholder="请输入300字以内的描述"
              ref="stu_answer"
              show-word-limit
              size="small"
              type="textarea"
              v-model="formData.stu_answer"
            />
          </el-form-item>
          <!-- 上传相关附件 -->
          <el-form-item label="相关附件" prop="stu_enclosure_url">
            <div class="fj_info">1、请上传1-10份相关附件;</div>
            <div class="fj_info">
              2、单个附件大小不超过100M,支持JPG、JPEG、PNG、docx、doc、pdf、mp4等格式;
            </div>
            <div class="upload_box">
              <div
                :key="index"
                class="img_list"
                v-for="(item, index) in stu_fileList"
              >
                <div>
                  <el-image :src="fileImg" class="home_icon"></el-image>
                  <div class="item_name">{{ item.name }}</div>
                  <div class="flex">
                    <div
                      @click="handleRemove(item)"
                      class="item_remove"
                      v-show="is_end===true"
                    >
                      删除
                    </div>
                    <div
                      @click="handleCheck(item.url_open)"
                      class="item_remove"
                    >
                      查看作业
                    </div>
                  </div>
                </div>
              </div>
              <!--                <div v-if="stu_fileList.length <= 0" class="img_list">-->
              <!--                  <div>-->
              <!--                    <div class="item_name">暂无相关附件</div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <el-upload
                :before-upload="beforeAvatarUpload"
                :disabled="isUploading || stu_fileList.length >= 10"
                :file-list="stu_fileList"
                :http-request="uploadPicture"
                :limit="10"
                :show-file-list="false"
                action="#"
                list-type="picture-card"
                name="multipartFile"
                ref="formData.stu_enclosure_url"
                v-if="stu_fileList.length < 10 "
              >
                <i
                  class="el-icon-plus avatar-uploader-icon"
                  v-if="!isUploading"
                ></i>
                <i
                  class="el-icon-loading avatar-uploader-icon"
                  v-if="isUploading"
                ></i>
              </el-upload>

            </div>
            <!-- </div> -->
          </el-form-item>
          <!-- 我的作业（已提交待评分 -不可编辑 ）-->
          <!-- <div v-if="student_status == 1 || student_status == 2">
          <div class="cont_title">
            <div class="line"></div>
            我的作业
          </div>
          <el-form-item label="文字描述(如超过300字，建议以附件形式上传)">
            <el-input
              type="textarea"
              v-model="formData.stu_answer"
              placeholder="请输入300字以内的描述"
              size="small"
              :maxlength="300"
              show-word-limit
              :disabled="true"
            />
          </el-form-item>
          <el-form-item label="相关附件">
            <div class="fj_info">1、请上传1-10份相关附件;</div>
            <div class="fj_info">
              2、单个附件大小不超过100M,支持JPG、JPEG、PNG、docx、doc、pdf、mp4等格式;
            </div>
            <div class="upload_box">
              <div
                class="img_list"
                v-for="(item, index) in stu_fileList"
                :key="index"
              >
                <div>
                  <el-image :src="fileImg"></el-image>
                  <div class="item_name">{{ item.name }}</div>
                </div>
              </div>
              <div v-if="stu_fileList.length <= 0" class="img_list">
                <div>
                  <div class="item_name">暂无相关附件</div>
                </div>
              </div>
            </div>
          </el-form-item>
        </div> -->
          <!-- 评阅结果 -->
          <div style="height: 350px" v-if="student_status == 2">
            <div class="cont_title">
              <div class="line"></div>
              <div class="lf">
                <span>评阅结果</span>
              </div>
            </div>
            <div class="cont_title_2" v-if="this.time_out != 3">导师评分</div>
            <div class="cont_info" v-if="this.time_out != 3">{{ score }}</div>
            <div class="cont_title_2" v-if="this.time_out == 3">导师评语</div>
            <div class="cont_info" v-html="teacher_commment" v-if="this.time_out == 3">}</div>
            <div class="cont_title_2" v-if="this.time_out != 3">相关附件</div>
            <div class="upload_box" v-if="this.time_out != 3">
              <div v-if="tea_scoreFileList.length > 0">
                <div
                  :key="index"
                  class="img_list"
                  v-for="(item, index) in tea_scoreFileList"
                >
                  <div>
                    <el-image :src="fileImg"></el-image>
                    <div class="item_name">{{ item.name }}</div>
                    <div
                      @click="downloadScoreFile(item.url)"
                      class="item_remove"
                    >
                      下载
                    </div>
                  </div>
                </div>
              </div>

              <div class="img_list" v-if="tea_scoreFileList.length <= 0">
                <div>
                  <div class="item_name">暂无相关附件</div>
                </div>
              </div>
            </div>
          </div>
          <el-form-item class="btn">
            <el-button @click="confirm" type="primary" v-preventReClick="1000"
            >提交
            </el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!--查看作业图片  -->
    <el-dialog
      :before-close="handleImageClose"
      :visible.sync="dialogVisibleImage"
      append-to-body
      title="作业"
      width="30%"
    >
      <el-image
        :lazy='true'
        :preview-src-list="srcList"
        :src="srcList_url_open"
      ></el-image>
    </el-dialog>
  </div>

</template>

<script>
  import {delPic, downloadTeacherWork, getStudentWork, subStudentWork} from "r/index/homework";
  import {achievementCallBack, cosSignature} from "r/index/cosUpload";
  import fileImg from "@/assets/img/homework/file_img.png";
  import COS from "cos-js-sdk-v5";

  export default {
    name: "ReadForm",
    props: {
      dialogVisibleFu: Boolean,
      timeOut: [Number, String], //判断是超时
      work_contexts: [Number, String], //作业内容
      enclosure_urls: [Object, String], //老师作业地址
      work_nums: [Number],
      workName: [String], //作业名字
      workType: [Number, String],
      student_status: [Number], //作业审核状态
      order_nums: [Number], //作业状态
      end_time: [Boolean], //作业截止时间
    },
    data() {
      const validateNum = (rule, value, callback) => {
        console.log(value, "value");
        // let code = this.$store.state.indexIdentity;
        // this.formData.stu_enclosure_url = window.localStorage.getItem(
        //   "student_file_" + window.localStorage.getItem("index-phone-all") + code
        // );
        // console.log(
        //   this.stu_fileList,' this.stu_fileList'
        // );
        // return
        // this.formData.stu_enclosure_url = this.stu_fileList
        console.log(this.formData.stu_answer);
        console.log(this.formData.stu_enclosure_url);
        if (
          this.formData.stu_answer == "" &&
          this.formData.stu_enclosure_url == ""
        ) {
          callback(new Error("请至少填写一项"));
        } else {
          callback();
        }
      };
      return {
        dialogVisible: this.dialogVisibleFu,
        dialogVisibleImage: false,
        referRules: {
          stu_answer: [
            {required: true, validator: validateNum, trigger: "blur"},
          ],
          stu_enclosure_url: [
            {required: true, validator: validateNum, trigger: "blur"},
          ],
        },
        titleName: "",
        isUploading: false, // 图片上传蒙层
        work_context: "", //简介
        work_name: "", //作业名称
        work_type: this.workType,
        is_end: '',//是否营地截止
        time_out: 1,
        fileImg: fileImg,
        loading: false,
        formData: {
          type: this.workType,
          teacher_id: "",
          stu_answer: "",
          stu_enclosure_url: "",
        }, // 老师-评阅结果
        disabled: false, //限制输入框
        stu_enclosure_url: "",//学生的已经提交的相关附件
        stu_fileListoss: "", // 学生-上传相关附件（展示-存在缓存）
        teaFileList: [], // 老师-布置作业附件
        stu_fileList: [], //学生上传的文件(传给接口的格式)
        score: "", //老师评分
        teacher_commment: "", //老师评语
        tea_scoreFileList: [], //老师评语的附件
        srcList_url_open: '',//打开的图片url_open
        srcList: [], //在线预览图片-放大
        fileList: [],//传给后端的url
      };
    },
    created() {
      let code = this.$store.state.indexIdentity;
      let stu_fileListoss = window.localStorage.getItem(
        "student_file_" + window.localStorage.getItem("index-phone-all") + code
      );
      if (stu_fileListoss && JSON.parse(stu_fileListoss).length > 0) {
        this.stu_fileListoss = JSON.parse(stu_fileListoss);
      }
    },
    methods: {
      open() {
        this.titleName = this.order_nums;
        this.getTeachList();
        if (this.student_status == 0) {
          this.formData = {};
        } else if (this.student_status == 1) {
          this.getStudentWork(this.student_status);
        } else if (this.student_status == 2) {
          this.getStudentWork(this.student_status);
        }
      },
      //老师作业展示
      getTeachList() {
        let url = this.enclosure_urls;
        if (this.enclosure_urls) {
          let arr = url.split(",");
          arr.forEach((item) => {
            this.teaFileList.push({
              url: item,
              name: item.split("work/")[1],
              status: "success",
            });
          });
        } else {
          this.stu_fileList = [];
        }
      },
      // 下载老师附件
      downloadStuFile(url) {
        downloadTeacherWork({enclosure_url: url}).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          let url = res.data.url;
          let reg = /(\.pdf|\.txt|\.mp3|\.mp4)/;
          let regImg = /(\.jpg|\.png|\.jpeg)/;
          if (reg.test(url) || regImg.test(url)) {
            window.open(url, "_black");
          } else {
            window.open(url, "_self");
          }
          this.$message.success("下载成功");
        });
      },
      // 下载老师评分结果
      downloadScoreFile(url) {
        downloadTeacherWork({enclosure_url: url}).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          let url = res.data.url;
          let reg = /(\.pdf|\.txt|\.mp3|\.mp4)/;
          let regImg = /(\.jpg|\.png|\.jpeg)/;
          if (reg.test(url) || regImg.test(url)) {
            window.open(url, "_black");
          } else {
            window.open(url, "_self");
          }
          this.$message.success("下载成功");
        });
      },
      // 学生作业回显
      getStudentWork(student_status) {
        let stu_work_num = this.work_nums;
        getStudentWork({teacher_id: stu_work_num}).then((res) => {
          if (res.data.code != 200) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);
          if (student_status == 1) {
            // 学生已提交
            this.formData.stu_answer = res.data.data.stu_answer;
            //学生的已经提交的相关附件
            this.formData.stu_enclosure_url = res.data.data.stu_enclosure_url;
            let gotfileList = res.data.data.work;
            if (gotfileList) {
              this.stu_fileList = gotfileList.map((item) => {
                return {
                  name: decodeURI(item.url.split("/work/")[1]),
                  url: item.url,
                  url_open: decodeURI(item.url_open),
                  type: item.type,
                };
              });
              this.fileList = gotfileList.map((item) => {
                return {
                  url_open: decodeURI(item.url_open),
                };
              });
              console.log(this.stu_fileList)
            }
            this.$forceUpdate();
          } else if (student_status == 2) {
            //老师已评阅
            this.formData.stu_answer = res.data.data.stu_answer;
            let gotfileList = res.data.data.work;
            if (gotfileList) {
              this.stu_fileList = gotfileList.map((item) => {
                return {
                  name: item.url.split("/work/")[1],
                  url: item.url,
                  url_open: item.url_open,
                  type: item.type,
                };
              });
            }
            this.score = res.data.data.score; //老师评分
            this.teacher_commment = res.data.data.teacher_commment; //老师评语
            let scoreFileList = res.data.data.teacher_url;
            if (scoreFileList) {
              let arr = scoreFileList.split(",");
              this.tea_scoreFileList = arr.map((item) => {
                return {
                  name: item.split("work/")[1],
                  url: item,
                };
              });
            }
            this.$forceUpdate(); //强制刷新
          }
        });
      },
      // 成功确认取消,关闭弹框，修改父组件的值
      cancelBtnTrue() {
        let code = this.$store.state.indexIdentity;
        window.localStorage.removeItem(
          "student_file_" + window.localStorage.getItem("index-phone-all") + code
        );
        this.stu_fileList = [];
        this.stu_fileListoss = "";
        this.teaFileList = [];
        this.$refs["formRef_stu"].resetFields();
        this.dialogVisible = false;
        this.$emit("closeDialog", this.dialogVisible, true);
        // this.$parent.getTeacherWork();
        // location.reload();
      },
      // 取消,关闭弹框，修改父组件的值
      cancelBtn() {
        let code = this.$store.state.indexIdentity;
        window.localStorage.removeItem(
          "student_file_" + window.localStorage.getItem("index-phone-all") + code
        );
        this.stu_fileList = [];
        this.stu_fileListoss = "";
        this.teaFileList = [];
        this.dialogVisible = false;
        this.$emit("closeDialog", this.dialogVisible, false);
      },
      //上传前
      beforeAvatarUpload(file) {
        let isName = false;
        for (let i = 0; i < this.stu_fileList.length; i++) {
          if (this.stu_fileList[i].name == file.name) {
            isName = true;
            break;
          }
        }
        if (isName) {
          this.$message.info("不能上传同名文件");
          return false;
        }
        const isLt500M = file.size / 1024 / 1024 < 500;
        if (!isLt500M) {
          this.$message.info("上传图片大小不能超过 500MB!");
        }
        return isLt500M;
      },
      uploadPicture(params) {
        this.isUploading = true;
        this.file = params.file;
        let raw = params.file;
        let image = {
          name: raw.name,
          size: raw.size,
          type: raw.type,
        };
        this.getCosConfigs(image, params.file);
      },
      // cos上传
      async getCosConfigs(image, file) {
        this.isUploading = true;
        await cosSignature({image: image, index: '2'})
          .then((res) => {
            console.log(res, "开始上传图片");
            let resData = res.data.data
            if (res.data.code === 200) {
              const cos = new COS({
                // 必选参数
                getAuthorization: (options, callback) => {
                  callback({
                    TmpSecretId: resData.credentials.credential.credentials.tmpSecretId,
                    TmpSecretKey: resData.credentials.credential.credentials.tmpSecretKey,
                    XCosSecurityToken: resData.credentials.credential.credentials.sessionToken,
                    StartTime: resData.credentials.credential.startTime, // 时间戳，单位秒，如：1580000000
                    ExpiredTime: resData.credentials.credential.expiredTime, // 时间戳，单位秒，如：1580000900
                  });
                },
              });
              cos.putObject({
                  Bucket: resData.credentials.bucket,
                  Region: resData.credentials.region,
                  Key: resData.key,
                  Body: file,
                  onProgress: (progressData) => {
                    console.log("上传进度：" + JSON.stringify(progressData));
                  },
                },
                (err, data) => {
                  console.log(data);
                  if (err) {
                    console.log(err);
                    this.isUploading = false;
                    this.$message.error('上传失败！')
                  } else {
                    console.log(data);
                    this.signUploadSuccess('http://' + data.Location, resData);
                  }
                }
              );
            } else {
              this.isUploading = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.isUploading = false;
          });
      },
      // cos上传-告诉后端上传成功
      async signUploadSuccess(path, resData) {
        await achievementCallBack({key: resData.key, index: 2}).then(res => {
          if (res.data.code != 200) {
            this.loading = false;
            this.isUploading = false;
            return this.$message.error('上传失败！');
          }
          this.isUploading = false;
          this.$message.success('上传成功！');
          this.resultPicture = path + "?t=" + Date.parse(new Date());
          this.$forceUpdate();
          let obj = {
            url_open: res.data.picture_path,
            name: decodeURI(res.data.real_filename.split("/work/")[1]),
            // savefile: res.data.config.savefile,
            // name: res.data.config.savefile.split("/work/")[1],
          };
          this.stu_fileList.push(obj);
          this.fileList.push(obj);
          this.stu_fileListoss = this.stu_fileList
            .map((item) => {
              return item.url_open;
            })
            .join(",");
          let code = this.$store.state.indexIdentity;
          window.localStorage.setItem("student_file_" +
            +window.localStorage.getItem("index-phone-all") +
            code,
            JSON.stringify(this.stu_fileListoss)
          );
        }).catch(err => {
          this.isUploading = false;
        })
      },
      // async getOssConfigs(image, file) {
      //   let obj = {
      //     url: "",
      //     savefile: "",
      //   };
      //   await PostWorkStudentOss({
      //     image: image,
      //     teacher_id: this.work_nums,
      //   })
      //     .then((res) => {
      //       if (res.data.code === 200) {
      //         let oss_url = res.data.config.host;
      //         let filename = res.data.config.file;
      //         let formData = new FormData();
      //         formData.append("OSSAccessKeyId", res.data.config.accessid);
      //         formData.append("policy", res.data.config.policy);
      //         formData.append("signature", res.data.config.signature);
      //         formData.append("filename", res.data.config.file);
      //         formData.append("key", res.data.config.savefile);
      //         formData.append("callback", res.data.config.callback);
      //         formData.append("success_action_status", 200);
      //         formData.append("file", file);
      //         this.upload(oss_url, formData)
      //           .then((ress) => {
      //             console.log(ress, "ress");
      //             if (ress.data.code != 200) {
      //               this.isUploading = false;
      //               return this.$message.error(ress.data.msg);
      //             }
      //             this.resultPicture = filename + "?t=" + Date.parse(new Date());
      //             this.$forceUpdate();
      //             this.$message.success("上传成功");
      //             obj = {
      //               url_open: ress.data.picture_path,
      //               savefile: res.data.config.savefile,
      //               name: res.data.config.savefile.split("/work/")[1],
      //             };
      //             this.stu_fileList.push(obj);
      //             this.fileList.push(obj);
      //             this.stu_fileListoss = this.stu_fileList
      //               .map((item) => {
      //                 return item.url_open;
      //               })
      //               .join(",");
      //
      //             let code = this.$store.state.indexIdentity;
      //             window.localStorage.setItem("student_file_" +
      //               +window.localStorage.getItem("index-phone-all") +
      //               code,
      //               JSON.stringify(this.stu_fileListoss)
      //             );
      //             this.isUploading = false;
      //           })
      //           .catch((err) => {
      //             this.isUploading = false;
      //             this.$message.error(err);
      //           });
      //       } else {
      //         this.isUploading = false;
      //         this.$message.error(res.data.msg);
      //       }
      //     })
      //     .catch((err) => {
      //       this.isUploading = false;
      //     });
      // },
      // 下载证明资料图片
      handleDownload(file) {
        // console.log(file,'file22');
        window.location.href = file.url;
      },
      //删除图片
      handleRemove(file) {
        console.log(file, "file");
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let getfile = file.url_open;
            // console.log(savefile.match(/^(?:[^\/]|\/\/)*/),'222222')；//获取域名
            let str = getfile.split(
              "https://campiste-1320656968.cos.ap-beijing.myqcloud.com/"
            ); //https://campiste-1320656968.cos.ap-beijing.myqcloud.com/进行分割，
            let savefile = str[1].split('?')[0];
            let stu_work_num = this.work_nums;
            delPic({object: savefile, teacher_id: stu_work_num}).then((res) => {
              // console.log(res, "delpic");
              if (res.data.code != 200) {
                return this.$message.error(res.data.msg);
              }
              this.stu_fileList.forEach((item, idx) => {
                if (item.url_open == file.url_open) {
                  this.stu_fileList.splice(idx, 1);
                  // this.fileList.splice(idx, 1);
                  let code = this.$store.state.indexIdentity;
                  this.stu_fileListoss = this.stu_fileList
                    .map(function (item, index) {
                      return item.url;
                    })
                    .join(",");
                  this.$message.success(res.data.msg);
                  window.localStorage.setItem("student_file_" +
                    window.localStorage.getItem("index-phone-all") +
                    code,
                    this.stu_fileListoss
                  );
                }
              });
            });
          })
          .catch((err) => {
            // console.log(err, "err");
            this.$message({
              type: "info",
              message: "取消删除",
            });
          });
      },
      // 查看已上传的学生作业
      handleCheck(url) {
        // 是否为图片
        if (url.match(/\.(jpeg|jpg|gif|png)$/) != null) {
          this.dialogVisibleImage = true;
          this.srcList_url_open = url
          this.srcList = this.stu_fileList.map((item) => {
            return item.url_open
          })
        } else {
          let onlineViewType = [
            "doc",
            "docx",
            "xls",
            "xlsx",
            "xlsm",
            "ppt",
            "pptx",
          ];
          let fileTypeName = url
            .substring(url.lastIndexOf(".") + 1, url.length)
            .split("?")[0];
          let isWord = onlineViewType.find((type) => type === fileTypeName);
          console.log(isWord, 'isWord')
          if (isWord == 'ppt' || isWord == 'pptx') {
            url = "http://view.officeapps.live.com/op/view.aspx?src=" + url;
          } else {
            url = url;
          }
          window.open(url, "_blank");
        }
      },
      handleImageClose(done) {
        this.$confirm("确认关闭？")
          .then((_) => {
            done();
          })
          .catch((_) => {
          });
      },
      // 未完成
      notReadHandle() {
        this.formData.teacher_id = this.work_nums;
        this.formData.stu_enclosure_url = this.stu_fileList.map((item) => {
          return decodeURI(item.url_open.split('?sign=')[0]);
        })
          .join(",");
        this.formData.type = this.work_type;
        console.log(this.formData)
        subStudentWork(this.formData)
          .then((response) => {
            // console.log(response);
            //作业无修改
            if (response.data.code == 4000343) {
              this.cancelBtn()
              return this.$message.error(response.data.msg);
            }
            if (response.data.code != 200) {
              return this.$message.error(response.data.msg);
            }
            this.$message.success(response.data.msg);
            this.cancelBtnTrue();
          })
          .catch((error) => {
            console.log(error);
          });
      },
      // 提交按钮
      confirm() {
        if (this.stu_fileList.length == 0) {
          return this.$message.success('请上传相关附件再提交')
        }
        this.$confirm(
          "提交后不可以填写其他作业，但在截止日期前仍可以修改本次作业，将以最后一次提交的为准。您确认要提交本次作业吗？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$refs["formRef_stu"].validate((valid) => {
              if (!valid) return;
              // console.log(valid, "valid");
              // 未完成
              // return
              this.notReadHandle();
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
    },
    watch: {
      dialogVisibleFu() {
        this.dialogVisible = this.dialogVisibleFu;
      },
      work_nums() {
        this.formData.teacher_id = this.work_nums;
      },
      workName() {
        this.work_name = this.workName;
      },
      workType() {
        this.work_type = this.workType;
        this.formData.type = this.workType;
      },
      timeOut() {
        this.time_out = this.timeOut;
        console.log(this.time_out, "timeout");
      },
      end_time() {
        this.is_end = this.end_time;
        console.log(this.is_end, "is_end");
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "a/scss/btn";
  @import "a/scss/homework/refer";

  .flex {
    display: flex;
  }
</style>






