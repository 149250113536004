/* eslint-disable */
import {request} from '../network';
import store from '@/store';


// 获取营地作业列表
export function getTeacherWork(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/download/getTeacherWork',
    params: data
  })
}

// 获取学生上传作业的oss
export function PostWorkStudentOss(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/download/getPostWorkStudent',
    data
  })
}


//下载
export function downloadTeacherWork(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/download/getDownLoadTeacherWork',
    params: data
  })
}

//删除图片
export function delPic(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/download/delPic',
    params: data
  })
}

// 获取学生提交营地作业
export function getStudentWork(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/download/getStudentWork',
    params: data
  })
}

// 提交营地作业
export function subStudentWork(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/download/subStudentWork',
    params: data
  })
}
