<template>
  <div class="homework">
    <div class="title">
      <div
        class="homework_titile"
        v-if="$store.state.human == 1"
      >
        作业
      </div>
      <div class="homework_titile" v-else>营地作业</div>
    </div>
    <div class="content_empty" v-if="total <= 0">
      <div>
        <img alt="" src="@/assets/img/homework/homework.png"/>
      </div>

      <p v-if="isShowinfo">同学你好，老师暂未发布作业</p>
    </div>
    <div class="home_content" v-if="total > 0">
      <div :key="item.id" class="each_content" v-for="item in homeworkList">
        <div class="top flex">
          <div class="homework_name flex">
            <div class="work_name">{{ item.work_name }}</div>
            <span class="status_0" v-if="item.student_status == 0">未完成</span>
            <span class="status_1" v-if="item.student_status == 1">待评阅</span>
            <span class="status_2" v-if="item.student_status == 2">已评阅</span>
          </div>
          <!-- <el-button class="btn_refer" @click="toRefer(item)"
            >查看作业</el-button
          > -->
          <el-button
            :disabled="
              !(
                (item.is_submit == 0 && !item.disabled) ||
                (item.is_submit == 1 && item.disabled)
              )
            "
            @click="toRefer(item)"
            class="btn_refer"
          >查看作业
          </el-button
          >
          <!-- <el-button type="info" plain disabled>警告按钮</el-button> -->
        </div>
        <div class="line"></div>
        <div class="bottom">
          <div v-html="item.work_context"></div>

          <div class="time flex">
            <div>发布时间：{{ item.start_time }}</div>
            <div>截止日期：{{ item.end_time }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination" v-if="total > 0">
      <el-pagination
        :current-page="currentPage"
        :page-size="pageSize"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <!-- 下载作业 -->
    <refer
      :dialogVisibleFu="dialogVisibleFu"
      :enclosure_urls="enclosure_url"
      :end_time="end_time"
      :order_nums="order_num"
      :student_status="student_status"
      :timeOut="time_out"
      :workName="workName"
      :workType="workType"
      :work_contexts="work_context"
      :work_nums="work_num"
      @closeDialog="closeDialog"
    ></refer>
  </div>
</template>
<script>
  /* eslint-disable */
  import refer from "v/index/camp/homework/refer";
  import {getTeacherWork} from "r/index/homework";

  export default {
    name: "homework",
    components: {
      refer,
    },
    data() {
      return {
        isShowinfo: false,
        pageSize: 10,
        currentPage: 1,
        total: 0,
        homeworkList: [],
        dialogVisibleFu: false,
        timeOut: 1, //提交作业时间状态
        work_context: "",
        enclosure_url: "",
        time_out: 0,
        work_num: 0,
        workName: "",
        workType: null,
        order_num: 0,
        student_status: 0,
        end_time: null,
        disabled: false,
      };
    },
    created() {
      this.getTeacherWork();
    },
    watch: {},
    methods: {
      handleSizeChange(val) {
        this.pageSize = val;
        // console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        // console.log(`当前页: ${val}`);
        this.getTeacherWork();
      },
      // 子组件触发，关闭确认报名弹框
      closeDialog(val, type) {
        this.dialogVisibleFu = val;
        this.getTeacherWork();
      },
      toRefer(item) {
        //time_out：提交作业时间状态
        if (item.time_out == 1 || 3) {
          // if (item.is_submit == 1) {
          // }
          if (item.time_out == 3) {
            this.time_out = item.time_out;
            console.log(this.timeOut, "this.timeOut ");
          }
          this.dialogVisibleFu = true;
          // console.log(item.work_num,'val')
          this.work_context = item.work_context;
          this.enclosure_url = item.enclosure_url;
          this.work_num = item.id;
          this.workName = item.work_name; //作业名字
          this.workType = item.type;
          this.order_num = item.work_num;
          this.student_status = item.student_status;
          //作业截止时间
          let endTime = new Date((item.end_time).replace(/-/g, "/")).getTime()
          let nowDate = new Date().getTime()
          this.end_time = nowDate < endTime ? true : false
        } else if (item.time_out == 2) {
          return this.$message.error("填写作业时间未开始");
        }

        // else if (item.time_out == 3) {
        //   return this.$message.error("填写作业时间已截止");
        // }
      },
      getTeacherWork() {
        getTeacherWork({page: this.currentPage}).then((res) => {
          // console.log(res, "getTeacherWork");
          if (res.data.code == 400001) {
            this.isShowinfo = false;
            return this.$message.error(res.data.msg);
          }
          if (res.data.code != 200) {
            this.isShowinfo = true;
            return this.$message.error(res.data.msg);
          }
          this.homeworkList = res.data.data.list;
          this.homeworkList.forEach((item) => {
            if (res.data.data.type.indexOf(item.type) != -1) {
              //在数组中没找到指定元素则返回 -1
              item.disabled = true;
            } else {
              item.disabled = false;
            }
            console.log(this.homeworkList)
          });
          this.isShowinfo = true;
          this.total = res.data.data.count;
          // this.$message.success(res.data.msg);
        });
      },
    },
  };
</script>


<style lang="scss">
  @import "a/scss/btn";

  .flex {
    display: flex;
  }

  .homework {
    font-family: PingFangSC-Medium, PingFang SC;
    // background: #ffffff;
    // width: 856px;

    // 空状态
    .title {
      padding: 50px;

      .homework_titile {
        font-size: 24px;
        font-weight: 500;
        color: #1b1419;
        line-height: 24px;
        margin-bottom: 40px;
        text-align: center;
      }
    }

    .content_empty {
      padding-bottom: 200px;

      p,
      div {
        text-align: center;
      }
    }

    // 有作业时候F
    .title {
      padding: 50px 50px 20px 50px;

      .homework_titile {
        font-size: 24px;
        font-weight: 500;
        color: #1b1419;
        line-height: 24px;
        text-align: center;
      }
    }

    .home_content {
      padding-bottom: 20px;
      font-size: 13px;

      .each_content {
        padding: 25px 30px;
        background-color: #f7f7f7;
        margin: 0 30px 20px 30px;
        // border-radius: 25px;
        .top {
          margin-bottom: 10px;
          justify-content: space-between;

          .homework_name {
            .work_name {
              padding: 7px 20px 7px 0;
              font-size: 14px;
              font-weight: 500;
              color: #333333;
              line-height: 20px;
            }

            span {
              width: 65px;
              line-height: 34px;
              text-align: center;
              border-radius: 10px;
              font-size: 12px;
              font-weight: 500;
            }

            .status_0 {
              background: #ffebf9;
              color: #60194a;
            }

            .status_1 {
              background: #fff0e5;
              color: #ff6f00;
            }

            .status_2 {
              background: #e4fff9;
              color: #0a9071;
            }
          }

          .btn_refer {
            // width: 88px;
            background: var(--color);
            border-radius: 4px;
            border-color: var(--color);
            opacity: 0.9;
            font-size: 14px;
            text-align: center;
            font-weight: 400;
            color: #ffffff;
            // line-height: 33px;
            // cursor: pointer;
            &.is-disabled {
              color: #fff;
              background-color: #c8c9cc;
              border-color: #c8c9cc;
            }
          }
        }

        .line {
          border-bottom: 1px solid #d8d8d8;
        }

        .bottom {
          //+ 相邻选择器
          // p+div{
          //   font-size: 20px;
          // }
          :first-child {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }

          .time > div {
            margin-right: 20px;
            font-size: 12px;

            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }
        }
      }
    }

    .pagination {
      position: relative;
      float: right;
    }
  }
</style>


